<template>
  <v-app-bar
    app
    bottom
    color="secondary"
  >
    <v-container>
      <v-row align="center">
        <v-col cols="4">
          <Logo/>
        </v-col>
        <v-col
          cols="2"
        ></v-col>
        <v-col
          cols="3"
          align="right"
        >
          <v-btn
            color="tertiary"
            :block="$vuetify.breakpoint.smAndUp"
            @click="$emit('rating')"
          >
            <v-icon
              :large="$vuetify.breakpoint.smAndUp"
              color="secondary"
            >
              mdi-thumb-up-outline
            </v-icon>
          </v-btn>
          <Rating
            :dialog="ratingDialog"
            @close="ratingDialog = false"
          />
        </v-col>
        <v-col
          cols="3"
          align="right"
        >
          <v-btn
            color="tertiary"
            :block="$vuetify.breakpoint.smAndUp"
            @click="$emit('email')"
          >
            <v-icon
              :large="$vuetify.breakpoint.smAndUp"
              color="secondary"
            >
              mdi-send
            </v-icon>
          </v-btn>
          <Email
            :dialog="emailDialog"
            @close="emailDialog = false"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-app-bar>
</template>

<script>
import { mapActions } from 'vuex';
import lazyLoad from '@/utils/lazyLoad';

export default {
  name: 'StickyButton',
  components: {
    Logo: lazyLoad('components/Logo'),
    Email: lazyLoad('components/Dialogs/Email'),
    Rating: lazyLoad('components/Dialogs/Rating'),
  },
  mounted() {
    this.reset();
  },
  data: () => ({
    emailDialog: false,
    ratingDialog: false,
  }),
  methods: {
    ...mapActions('email', [
      'reset',
    ]),
  },
};
</script>
